<script setup lang="ts">
import { Button, type ButtonVariants } from '@/components/ui/button'

export interface AppButtonProps {
  label?: string
  icon?: string
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
}

const props = withDefaults(defineProps<AppButtonProps>(), {
  variant: 'default',
  size: 'default',
  disabled: false,
  iconPosition: 'left'
})

const sizesClasses = computed(() => {
  switch (props.size) {
    case 'default':
      return {
        text: 'text-sm',
        icon: 'w-4 h-4'
      }
    case 'xs':
      return {
        text: 'text-xs',
        icon: 'w-3 h-3'
      }
    case 'sm':
      return {
        text: 'text-sm',
        icon: 'w-4 h-4'
      }
    case 'lg':
      return {
        text: 'text-lg',
        icon: 'w-6 h-6'
      }
    case 'icon':
      return {
        text: 'text-sm',
        icon: 'w-6 h-6'
      }
    default:
      return {
        text: 'text-sm',
        icon: 'w-4 h-4'
      }
  }
})

const labelToShow = computed(() => {
  return props.size !== 'icon' ? props.label : ''
})
</script>

<template>
  <Button :variant="variant" :size="size" :disabled="disabled" class="flex items-center" :class="sizesClasses?.text">
    <iconify-icon v-if="icon && iconPosition === 'left'" :icon="icon" :class="size !== 'icon' && 'mr-2'" />
    <slot>{{ labelToShow ?? '' }}</slot>
    <iconify-icon v-if="icon && iconPosition === 'right'" :icon="icon" :class="size !== 'icon' && 'ml-2'" />
  </Button>
</template>

<style scoped>

</style>
